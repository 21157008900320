import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import Layout from "../components/layout";
import SEO from "../components/common/SEO/";
import Hero from "../components/common/Hero/OverviewHero";

import { childOverview, overviewText, overviewImage } from "../styles/modules/page.module.scss";
import styled from "styled-components";

const OverviewContent = styled.section`
  padding-top: 1rem;
`;

const ChildOverview = ({ data }) => {
  const image = getImage(data.frontmatter.overviewImage)

  return (
    <div className={childOverview}>
      <Container>
        <Row>
          <Col lg={4}>
            <GatsbyImage
              image={image}
              alt={data.frontmatter.title}
              className={overviewImage}
            />
          </Col>
          <Col lg={8} className={overviewText}>
            <h3>{data.frontmatter.title}</h3>
            <hr />
            <p>{data.frontmatter.description}</p>
            <Link className="arrow-link" to={data.fields.slug}>
              Learn More
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const OverviewTemplate = ({ pageContext, data }) => {
  let currentPage = data.mdx;

  const {
    breadcrumb: { crumbs }
  } = pageContext;

  return (
    <Layout pageId={currentPage.slug}>
      <SEO title={currentPage.frontmatter.title} />
      <Hero
        title={currentPage.frontmatter.title}
        heroImage={
          currentPage.frontmatter.hero
        }
      />
      <Container>
        <Row>
          <Col>
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator=" » "
              crumbLabel={
                currentPage.slug === "reach-awards"
                  ? "Celebrate Those Who Reach Farther"
                  : currentPage.title
              }
            />
          </Col>
        </Row>
      </Container>
      <OverviewContent>
        {data.allMdx.nodes.map(childPage => {
          return <ChildOverview data={childPage} key={childPage.id} />;
        })}
      </OverviewContent>
    </Layout>
  );
};

export default OverviewTemplate;

export const pageQuery = graphql`query ($id: String) {
  mdx(id: {eq: $id}) {
    id
    slug
    frontmatter {
      title
      hero {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  }
  allMdx(
    filter: {frontmatter: {published: {eq: true}, parent: {id: {eq: $id}}}}
    sort: {order: ASC, fields: frontmatter___title}
    ) {
    nodes {
      id
      fields {
        slug
      }
      frontmatter {
        title
        description
        overviewImage {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
    }
  }
}
`;
